<template>
  <v-dialog width="600" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        @click="dialog = true"
        color="warning"
        title="Mover Demandas Em Lote"
      >
        <v-icon>far fa-hand-point-right </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title> Mover demandas deste usuario </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="user_id"
                :items="usersResponsibles"
                item-text="name"
                item-value="id"
                required
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="danger" @click="dialog = false"> Fechar </v-btn>
        <v-btn color="success" @click="updateAllDemands()"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import instanceAxios from "../../plugins/axios";
import ToastService from "../../services/ToastService";
export default {
  props: {
    users: {
      required: true,
      type: Array,
      default: function() {
        return [];
      },
    },

    remove_id: {
      required: true,
      type: [Number, String],
    },
  },

  computed: {
    usersResponsibles() {
      return this.users.filter((user) => {
        return user.role.type == "responsible";
      });
    },
  },

  data() {
    return {
      dialog: false,
      user_id: null,
    };
  },

  methods: {
    async updateAllDemands() {
      if (this.user_id == null) {
        ToastService("Selecione um usuario", "error");
        return;
      }

      await instanceAxios.put("demands/lote", {
        user_id_remove: this.remove_id,
        user_id: this.user_id,
      });

      ToastService('Demandas movidas com sucesso', 'success');

      this.dialog = false;
    },
  },
};
</script>

<style></style>
