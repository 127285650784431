<template>
  <v-container fluid>
    <div class="text-center">
      <v-toolbar dense floating>
        <v-text-field
          hide-details
          prepend-icon="fas fa-search"
          single-line
          label="Buscar..."
          @input="indexData()"
          v-model="query"
        ></v-text-field>
        <v-chip class="ma-2" color="primary" text-color="white">
          {{ dataTable.length }} Usuários(s)
        </v-chip>
        <v-chip
          v-show="me.role.id <= 5"
          class="ma-2"
          color="success"
          text-color="white"
          :to="{ path: 'usuarios/formulario' }"
        >
          Novo
        </v-chip>
      </v-toolbar>
    </div>

    <div class="text-center">
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
        v-show="dataLoading"
      ></v-progress-circular>
    </div>
    <Item
      v-for="item in dataTable"
      v-show="verifyList(item)"
      :key="item.id"
      :item="item"
      :list="headers"
    >
      <template v-slot:item>
        <v-col cols="12" class="pt-0 pb-0">
          <v-card-text class="ml-4">
            <b> Função </b> <span> {{ item.role.slug }} </span>
          </v-card-text>
          <v-divider class="mx-4 mt-0 mb-0"></v-divider>
        </v-col>
      </template>

      <template v-slot:acoes>
        <v-btn icon color="green" :to="{ path: 'usuarios/' + item.id }">
          <v-icon>far fa-eye</v-icon>
        </v-btn>
        <v-btn v-if="me.role.name !== 'sindico'" icon color="red">
          <v-icon>fas fa-trash</v-icon>
        </v-btn>
        <v-btn v-if="me.role.name !== 'sindico'"
          icon
          color="primary"
          :to="{ path: `usuarios/${item.id}/formulario` }"
        >
          <v-icon>fa fa-edit</v-icon>
        </v-btn>
        <MoveDemands v-if="me.role.name !== 'sindico'" :remove_id="item.id" :users="dataTable" />
      </template>
    </Item>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ToastService from "@/services/ToastService";
import UserService from "@/services/User/UserService";
import Item from "@/components/CardListItem/Item";
import MoveDemands from "../../components/User/MoveDemands.vue";

export default {
  name: "UserIndex",

  created: async function() {
    await this.indexData();
  },

  computed: {
    ...mapGetters({
      me: "getMe",
    }),
  },

  components: {
    Item,
    MoveDemands,
  },

  data: () => ({
    selected: [],
    dataTable: [],
    query: "",
    loading: true,
    confirmData: {},
    showDeleteModal: false,
    showForm: false,
    dataLoading: true,
    dataToModal: {},
    paginateParams: {},
    UserService: new UserService(""),
    headers: [
      { title: "NAME", nick: "Nome" },
      { title: "EMAIL", nick: "Email" },
      { title: "REGISTRATION", nick: "Matricula" },
    ],
  }),

  methods: {
    async indexData() {
      try {
        this.dataLoading = true;

        const res = await this.UserService.index("users?search=" + this.query);

        this.dataTable = res;
      } catch (exception) {
        ToastService(exception, "error");
      } finally {
        this.dataLoading = false;
      }
    },

    async deleteData(id) {
      try {
        await this.UserService.destroy("user/" + id);

        ToastService("Ação realizada com sucesso.", "success");

        await this.indexData();
      } catch (exception) {
        ToastService("Erro ao realizar operação.", "error");
      } finally {
        this.loading = false;
      }
    },

    verifyDeleteData(item) {
      this.confirmData = item;
      this.showDeleteModal = true;
    },

    async confirmDeleteData(item) {
      this.loading = true;

      this.cancelDeleteData();

      await this.deleteData(item.id);
    },

    cancelDeleteData() {
      this.confirmData = {};
      this.showDeleteModal = false;
    },

    redirectForm(path) {
      this.$router.push({ path: path });
    },

    async setPage(page) {
      await this.indexData(`?page=${page}`);
    },
    verifyList(usuario) {
      if (this.me.role.type == "responsible") {
        return true;
      } else {
        if (usuario.role.id >= 5) {
          return true;
        } else {
          false;
        }
      }
    },
  },
};
</script>
